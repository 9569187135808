<template>
	<div class="materialDetail">
		<el-form size="mini" label-width="100px" label-position='left'>
			<el-form-item label="图片:">
				<el-image 
				    style="width: 100px;"
				    :src="form.material_url" 
				    :preview-src-list="[form.material_url]">
				</el-image>
			</el-form-item>
			<el-form-item label="材料名称:">{{form.material_name}}</el-form-item>
			<el-form-item label="材料类型:">{{form.material_type}}</el-form-item>
			<el-form-item label="材料来源:">{{form.source}}</el-form-item>
			<el-form-item v-if="typeArr.indexOf(form.material_type) != -1" label="所属国家尺码:">{{form.country}}</el-form-item>
			<el-form-item v-if="typeArr.indexOf(form.material_type) != -1" label="码差:">{{form.code}}</el-form-item>
			<el-form-item v-if="typeArr.indexOf(form.material_type) != -1" label="码段:">{{form.size[0]}} {{form.size[1]}}</el-form-item>
			<el-form-item label="规格:">{{form.spec}}</el-form-item>
			<el-form-item label="单位:">{{form.unit}}</el-form-item>
			<el-form-item label="单价:">{{form.price}}</el-form-item>
			<el-form-item label="颜色:">{{form.color}}</el-form-item>
			<el-form-item label="数量:">{{form.num}}</el-form-item>
			<el-form-item label="付款方式:">{{form.pay_type}}</el-form-item>
			<el-form-item label="是否含运费:">{{form.is_express}}</el-form-item>
			<el-form-item label="入库日期:">{{form.warehouse_date}}</el-form-item>
			<el-form-item label="存放位置:">{{form.location}}</el-form-item>
			<el-form-item label="备注:">{{form.note}}</el-form-item>
		</el-form>
		<el-button v-if="form.id" type="primary" @click="dialogVisible = true">修改数量</el-button>
		<el-button v-if="form.id" type="primary" @click="dialogVisible2 = true">上传图片</el-button>
		<el-button v-if="form.id" type="primary" @click="dialogVisible3 = true">查看供应商</el-button>
		<el-dialog
		  title="修改材料数量"
		  :visible.sync="dialogVisible"
		  width="100%">
		  <el-form :model="data" :rules="rules" ref="ruleForm">
			  <el-form-item label="数量:" prop="num">
				  <el-input type="number" v-model="data.num"></el-input>
			  </el-form-item>
			  <el-form-item label="密钥:" prop="pwd">
				  <el-input type="text" v-model="data.pwd"></el-input>
			  </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" :loading="isLoading" @click="saveData">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog 
		title="上传图片"
		:visible.sync="dialogVisible2"
		width="100%">
			<el-form :model="picture" :rules="rules" ref="ruleForm2">
				  <el-form-item label="图片:" prop="url">
					  <el-upload
					    v-loading="loading"
					    class="avatar-uploader"
					    accept=".jpg,.jpeg,.png,.JPG,.PNG"
					    action=""
					    :show-file-list="false"
					    :http-request="picUpload"
					    :before-upload="beforeAvatarUpload">
					    <img v-if="picture.url" :src="picture.url" style="width: 100%;">
					    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					  </el-upload>
				  </el-form-item>
				  <el-form-item label="密钥:" prop="pwd">
					  <el-input type="text" v-model="picture.pwd"></el-input>
				  </el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
			  <el-button @click="dialogVisible2 = false">取 消</el-button>
			  <el-button type="primary" :loading="isLoading" @click="savePicture">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog
		title="供应商信息"
		:visible.sync="dialogVisible3"
		width="100%">
			<el-form v-if="Object.keys(supplierInfo).length==0" :model="supplier" :rules="rules" ref="ruleForm3">
				 <el-form-item v-if="form.supplier_id" label="密钥:" prop="pwd">
				 	<el-input type="text" v-model="supplier.pwd"></el-input>
				 </el-form-item>
				 <p v-else style="text-align: center;">暂无供应商信息!</p>
			</el-form>
			<el-form v-else size="mini" label-width="100px" label-position='left'>
				<el-form-item label="供应商名称:">
					<label>{{supplierInfo.supplier_name}}</label>
				</el-form-item>
				<el-form-item label="联系人:">
					<label>{{supplierInfo.contact_name}}</label>
				</el-form-item>
				<el-form-item label="联系方式:">
					<label>{{supplierInfo.contact}}</label>
				</el-form-item>
				<el-form-item label="地址:">
					<label>{{supplierInfo.address}}</label>
				</el-form-item>
				<el-form-item label="开户行:">
					<label>{{supplierInfo.bank}}</label>
				</el-form-item>	  
			</el-form>
			<span slot="footer" class="dialog-footer">
			  <el-button @click="dialogVisible3 = false">取 消</el-button>
			  <el-button v-if="Object.keys(supplierInfo).length==0" type="primary" :loading="isLoading" @click="getSupplier">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import uploadCos from "@/utils/upload_to_cos";
export default {
	components:{

	},
	data(){
		return {
			dialogVisible:false,
			isLoading:false,
			form:{},
			dialogVisible2:false,
			dialogVisible3:false,
			data:{
				num:'',
				pwd:'',
				id:''
			},
			picture:{
				url:'',
				pwd:'',
				id:''
			},
			supplier:{
				pwd:'',
				id:'',
				supplier_id:''
			},
			supplierInfo:{},
			loading:false,
			typeArr:['楦头','大底','中底','鞋垫'],
			rules: {
			  num: [
				{ required: true, message: '请输入材料数量', trigger: 'blur' },
			  ],
			  pwd:[
				  { required: true, message: '请输入密钥', trigger: 'blur' },
			  ],
			  url:[
				 { required: true, message: '请选择上传的图片', trigger: 'blur' },
			  ]
			}
		}
	},
	created(){
		let id = this.$route.query.id;
		if(!id){
			return;
		}
		this.data.id = id;
		this.picture.id = id;
		this.supplier.id = id;
		this.getMaterialInfo(id);
	},
	watch:{
		dialogVisible(){
			this.data.num = '';
		}
	},
	methods:{
		getMaterialInfo(id){
			this.$api.getMaterialInfo({id:id}).then((res)=>{
				this.form = res;
				this.supplier.supplier_id = this.form.supplier_id?this.form.supplier_id:'';
				this.form.material_url = 'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com'+this.form.material_url;
			})
		},
		saveData(){
			this.$refs['ruleForm'].validate((valid) => {
			  if (valid) {
				this.isLoading = true;
				this.$api.updateMaterialNum(this.data).then((res)=>{
					this.isLoading = false;
					if(res.status == 1){
						this.dialogVisible = false;
						this.$message.success(res.msg)
						this.getMaterialInfo(this.form.id);
					}else{
						this.$message.error(res.msg)
					}
				})
			  } else {
				this.$message.error('必填项未填')
				return false;
			  }
			});
		},
		beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2;
			
			if (!isLt2M) {
			  this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return isLt2M;
		},
		//上传图片
		picUpload(file) {
		  let that = this;
		  that.loading = true;
		  uploadCos([file.file], "works", (picture) => {
			   that.picture.url = picture[0]
			   that.loading = false;
		  });
		},
		savePicture(){
			this.$refs['ruleForm2'].validate((valid) => {
			  if (valid) {
				this.isLoading = true;
				this.$api.updateMaterialPicture(this.picture).then((res)=>{
					this.isLoading = false;
					if(res.status == 1){
						this.dialogVisible2 = false;
						this.picture.url = '';
						this.$message.success(res.msg)
						this.getMaterialInfo(this.form.id);
					}else{
						this.$message.error(res.msg)
					}
				})
			  } else {
				this.$message.error('必填项未填')
				return false;
			  }
			});
		},
		//获取供应商信息
		getSupplier(){
			this.$refs['ruleForm3'].validate((valid) => {
			  if (valid) {
				this.isLoading = true;
				this.$api.getSupplierInfo(this.supplier).then((res)=>{
					this.isLoading = false;
					if(res.status == 1){
						this.supplierInfo = res.data;
					}else{
						this.$message.error(res.msg)
					}
				})
			  } else {
				this.$message.error('必填项未填')
				return false;
			  }
			});
		}
	}
}
</script>

<style lang="scss">
body{
	background-color: #fff;
}
.materialDetail{
	background-color: #fff;
	padding: 20px;
	.el-form-item--mini.el-form-item{
		margin-bottom: 2px !important;
	}
}
</style>
